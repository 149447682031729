html {
   height: 100%;
}

body {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
   height: 100% !important;
   min-height: 1000px !important;
   overflow-x: hidden;
}

.p-tabview .p-tabview-panels {
   background-color: rgba(200, 200, 200, 0) !important;
   margin: 30px;
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
   padding: 0px !important;
}

.p-tabview .p-tabview-nav {
   background-color: rgba(200, 200, 200, 0) !important;
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
   background-color: rgba(200, 200, 200, 0) !important;
   border: none !important;
}

.tabView-wrapper {
   margin-left: 10%;
   height: 100%;
}

.accountScoreWrapper {
   font-size: 65px;
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   max-width: fit-content !important;
   border-radius: 50px;
}

.loadAccountScoreButton {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
}

.selectButtonWrapper {
   box-shadow: 2px 3px 5px rgb(150, 150, 150) !important;
   padding: 0 !important;
   margin: 0 !important;
   border-radius: 5px;
}

.highScoreRow {
   margin-top: 50px;
}

.highScoreCard {
   /* box-shadow: 2px 3px 5px rgb(121, 121, 121); */
   border-radius: 20px;
   padding: 8px;
   transition: .2s;
}

.highScoreCard:hover {
   box-shadow: 2px 3px 5px rgb(121, 121, 121);
   border-radius: 20px;
   padding: 8px;
}

.achievementCard {
   /* box-shadow: 2px 3px 5px rgb(121, 121, 121); */
   border-radius: 20px;
   border: 2px solid rgba(0, 0, 255, 0.233);
   padding: 8px;
   transition: .2s;
   margin-right: 10px !important;
}

.achievementCard:hover {
   box-shadow: 2px 3px 5px rgb(121, 121, 121);
   border-radius: 20px;
   padding: 8px;
   border: 2px solid rgba(0, 0, 255, 0);
   transition: .2s;
}

.notCompletedText {
   color: red !important;
}

.completedAchievementCard {
   border: 2px solid rgba(59, 138, 6, 0.945) !important;
   filter: brightness(120%);
   box-shadow: 2px 3px 5px rgba(121, 121, 121, 0.466);
   -webkit-animation: glowing 1s ease-in-out infinite alternate;
   -moz-animation: glowing 1s ease-in-out infinite alternate;
   animation: glowing 1s ease-in-out infinite alternate;
}

.completedAchievementCard:hover {
   filter: brightness(140%);
}

 @keyframes glowing {
   from {
     box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #f0f, 0 0 8px #00e64d9a, 0 0 10px #00e64dce, 0 0 12px #00e64dce, 0 0 14px #00e64d;
   }
   to {
     box-shadow: 0 0 2px #fff, 0 0 4px #ff4da6, 0 0 6px #ff4da6, 0 0 8px #ff4da6, 0 0 10px #00e64dce, 0 0 12px #00e64dce, 0 0 14px #00e64d;
   }
 }

h5 {
   color: green;
   font-weight: bold;
}

#achievements {
   height: 100% !important;
}

.totalAchievementBonusPoints {
   position: absolute;
   top: 5%;
   left: 40%;
   color: green;
   font-weight: bold;
}

.achievementValue {
   background-color: rgba(0, 128, 0, 0.493);
   color: white;
   width: 120px !important;
   text-align: center !important;
   margin: auto;
   border-radius: 20px;
}

.achievementValueGotten {
   color: green;
   font-weight: bold;
   width: 120px !important;
   text-align: center !important;
   border-radius: 20px;
}

.playLegManAchievement {
   width: 64px !important;
   height: 64px !important;
   background-image: url('../Sprites/Achievements/LegMan/PlayLegMan.png');
   background-repeat:no-repeat;
   background-position: 50% 35%;
   margin: auto;
   margin-top: 10px !important;
   position: relative;
   text-align: center;
}

.playSpaceFighterAchievement {
   width: 64px !important;
   height: 84px !important;
   background-image: url('../Sprites/Achievements/SpaceFighter/PlaySpaceFighter.png');
   background-repeat:no-repeat;
   background-position: 50% 35%;
   margin: auto;
   margin-top: -5px !important;
   position: relative;
   text-align: center;
}

.playHungryKidAchievement {
   width: 64px !important;
   height: 84px !important;
   background-image: url('../Sprites/Achievements/HungryKid/PlayHungryKidAchievement.png');
   background-repeat:no-repeat;
   background-position: 50% 35%;
   margin: auto;
   margin-top: -10px !important;
   position: relative;
   text-align: center;
}

.playBlockBreakerAchievement {
   width: 64px !important;
   height: 84px !important;
   background-image: url('../Sprites/Achievements/BlockBreaker/PlayBlockBreakerAchievement.png');
   background-repeat:no-repeat;
   background-position: 50% 35%;
   margin: auto;
   margin-top: 10px !important;
   position: relative;
   text-align: center;
}

.playMagicManAchievement {
   width: 64px !important;
   height: 84px !important;
   background-image: url('../Sprites/Achievements/MagicMan/PlayMagicManAchievement.png');
   background-repeat:no-repeat;
   background-position: 50% 35%;
   margin: auto;
   margin-top: 10px !important;
   position: relative;
   text-align: center;
}

.playJumpManAchievement {
   width: 64px !important;
   height: 84px !important;
   background-image: url('../Sprites/Achievements/JumpMan/JumpManAchievements.png');
   background-repeat:no-repeat;
   background-position: 50% 35%;
   margin: auto;
   margin-top: 10px !important;
   position: relative;
   text-align: center;
}

.playMonsterSmasherAchievement {
   width: 64px !important;
   height: 84px !important;
   background-image: url('../Sprites/Achievements/MonsterSmasher/PlayMonsterSmasherAchievement.png');
   background-repeat:no-repeat;
   background-position: 50% 35%;
   margin: auto;
   margin-top: -10px !important;
   position: relative;
   text-align: center;
}

.playComputerPowerAchievement {
   width: 64px !important;
   height: 84px !important;
   background-image: url('../Sprites/Achievements/ComputerPower/PlayComputerPowerAchievement.png');
   background-repeat:no-repeat;
   background-position: 50% 100%;
   margin: auto;
   margin-top: 10px !important;
   position: relative;
   text-align: center;
}

.playLastSurvivorAchievement {
   width: 64px !important;
   height: 84px !important;
   background-image: url('../Sprites/Achievements/LastSurvivor/LastSurvivor_Achievement.png');
   background-repeat:no-repeat;
   background-position: 50% 100%;
   margin: auto;
   margin-top: 10px !important;
   position: relative;
   text-align: center;
}

.playStackersAchievement {
   width: 64px !important;
   height: 84px !important;
   background-image: url('../Sprites/Achievements/Stackers/StackersAchievementCard.png');
   background-repeat:no-repeat;
   background-position: 50% 100%;
   margin: auto;
   margin-top: 10px !important;
   position: relative;
   text-align: center;
}