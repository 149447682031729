body {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
   height: 100% !important;
   min-height: 1200px !important;
   overflow-x: hidden;
   overflow-y: scroll;
}

.footer {
   position: relative;
   top: 95%;
   left: 47%;
   color: rgba(0, 128, 0, 0.74);
   font-size: 12px;
}

.App-0-0 {
   /* background-color: rgb(15, 15, 15);*/
   background-image: url(./SVG/background/waves_0_0.svg);
   height: 100%;
   /* arbtrirary large value to drag this color full length down */
   min-height: 1200px;
   background-repeat: repeat-x;
   overflow-y: hidden;
   overflow-x: hidden;
}

.App-0-1 {
   /* background-color: rgb(15, 15, 15);*/
   background-image: url(./SVG/background/waves_0_1.svg);
   height: 100%;
   /* arbtrirary large value to drag this color full length down */
   min-height: 1200px;
   background-repeat: repeat-x;
   overflow-y: hidden;
   overflow-x: hidden;
}

.App-1-0 {
   /* background-color: rgb(15, 15, 15);*/
   background-image: url(./SVG/background/waves_1_0.svg);
   height: 100%;
   /* arbtrirary large value to drag this color full length down */
   min-height: 1200px;
   background-repeat: repeat-x;
   overflow-y: hidden;
   overflow-x: hidden;
}

.App-1-1 {
   /* background-color: rgb(15, 15, 15);*/
   background-image: url(./SVG/background/waves_1_1.svg);
   height: 100%;
   /* arbtrirary large value to drag this color full length down */
   min-height: 1200px;
   background-repeat: repeat-x;
   overflow-y: hidden;
   overflow-x: hidden;
}

.App-2-0 {
   /* background-color: rgb(15, 15, 15);*/
   background-image: url(./SVG/background/waves_2_0.svg);
   height: 100%;
   /* arbtrirary large value to drag this color full length down */
   min-height: 1200px;
   background-repeat: repeat-x;
   overflow-y: hidden;
   overflow-x: hidden;
}

.App-2-1 {
   /* background-color: rgb(15, 15, 15);*/
   background-image: url(./SVG/background/waves_2_1.svg);
   height: 100%;
   /* arbtrirary large value to drag this color full length down */
   min-height: 1200px;
   background-repeat: repeat-x;
   overflow-y: hidden;
   overflow-x: hidden;
}

.App-3-0 {
   /* background-color: rgb(15, 15, 15);*/
   background-image: url(./SVG/background/waves_3_0.svg);
   height: 100%;
   /* arbtrirary large value to drag this color full length down */
   min-height: 1200px;
   background-repeat: repeat-x;
   overflow-y: hidden;
   overflow-x: hidden;
}

.App-3-1 {
   /* background-color: rgb(15, 15, 15);*/
   background-image: url(./SVG/background/waves_3_1.svg);
   height: 100%;
   /* arbtrirary large value to drag this color full length down */
   min-height: 1200px;
   background-repeat: repeat-x;
   overflow-y: hidden;
   overflow-x: hidden;
}

.App-4-0 {
   /* background-color: rgb(15, 15, 15);*/
   background-image: url(./SVG/background/waves_4_0.svg);
   height: 100%;
   /* arbtrirary large value to drag this color full length down */
   min-height: 1200px;
   background-repeat: repeat-x;
   overflow-y: hidden;
   overflow-x: hidden;
}

.App-4-1 {
   /* background-color: rgb(15, 15, 15);*/
   background-image: url(./SVG/background/waves_4_1.svg);
   height: 100%;
   /* arbtrirary large value to drag this color full length down */
   min-height: 1200px;
   background-repeat: repeat-x;
   overflow-y: hidden;
   overflow-x: hidden;
}

.gamechickenNavbar {
   width: 100%;
   box-shadow: 2px 3px 2px rgb(100, 100, 100);
   overflow: hidden;
}

.navbarBG {
   width: 100%;
   height: 100px;
}

.title {
   font-size: 30px;
   color: green;
   top: 28px;
   left: 5%;
   position: absolute;
}

.gameCard-hover {
   transition: .2s;
   transition-timing-function: ease-out;
}

.gameCard-wrapper {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   margin: 10px;
   position: relative;
}

.card-LegMan {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/LegManLogo_200.png');
   background-repeat: no-repeat;
   background-position: 50% 40%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.card-HungryKid {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/HungryKidLogo_200.png');
   background-repeat: no-repeat;
   background-position: -20% 40%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.card-SpaceFighter {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/SpaceFighterLogo_web.png');
   background-repeat: no-repeat;
   background-position: 25% 10%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.card-BlockBreaker {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/BlockBreaker_Logo.png');
   background-repeat: no-repeat;
   background-position: 20% 10%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.card-MonsterSmasher {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/MonsterSmasherLogo_150.png');
   background-repeat: no-repeat;
   background-position: 50% 35%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.card-ComputerPower {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/ComputerPowerLogo_150.png');
   background-repeat: no-repeat;
   background-position: 50% 35%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.card-MagicMan {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/MagicManTitleCard.png');
   background-repeat: no-repeat;
   background-position: 50% 35%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.card-Stackers {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/Stackers_GameCard.png');
   background-repeat: no-repeat;
   background-position: 10% 60%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.card-JumpMan {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/JumpManTitle_.png');
   background-repeat: no-repeat;
   background-position: 50% 35%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.card-LastSurvivor {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/LastSurvivor_MainSite.png');
   background-repeat: no-repeat;
   background-position: 10% 35%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.card-BowMaster {
   width: 150px !important;
   height: 150px !important;
   margin-top: 2vh;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   color: green;
   background-image: url('./Sprites/Logos/WIP-Game-Title-Card.png');
   background-repeat: no-repeat;
   background-position: 10% 35%;
   margin: 10px;
   position: relative;
   text-align: center;
}

.gameCard-play-button {
   position: absolute;
   width: 90%;
   bottom: 5%;
   left: 5%;
}

.playLabel {
   transition: 2s;
   transition-timing-function: ease-out;
   opacity: 0;
   position: absolute;
   left: 5%;
   bottom: 5%;
   width: 90%;
}

.gameCard-hover:hover {
   filter: brightness(110%);
   box-shadow: -3px 8px 10px rgb(100, 100, 100);
}

.gameCard-hover:hover .playLabel {
   transition: .5s;
   transition-timing-function: ease-out;
   opacity: 1;
}

.gameRow {
   width: 100%;
   display: flex;
}

.remix {
   width: 40%;
   left: 50%;
}

.remixButtonWrapper {
   position: fixed;
   width: 100%;
   top: 92% !important;
   text-align: center;
}

.socialWrapper {
   position: fixed;
   bottom: 2% !important;
   left: 80% !important;
   text-align: center;
}

.privacyPolicyWrapper {
   position: fixed;
   bottom: 5% !important;
   left: 42% !important;
   text-align: center;
}

.mobileContentWrapper {
   height: 100%;
   width: 100%;
   overflow: hidden;
   text-align: center;
}

.mobileUserDetected {
   height: 128px;
   width: 100%;
   animation-timing-function: steps(5);
   animation-iteration-count: infinite;
   background: url('./Sprites/SiteContent/MobileUserDetected.png') no-repeat center;
   z-index: 999;
   position: relative;
}

.mobileInsult {
   padding: 10px;
   text-align: center;
   overflow-wrap: break-word;
}

@keyframes movement {
   to {
      background-position-x: -320px;
   }
}

.player-runningR {
   margin-left: 10px;
   margin-top: 15px;
   height: 64px;
   border-bottom: 2px solid black;
   width: 64px;
   animation-name: movement;
   transform: translate(-50%, -50%);
   animation-duration: .5s;
   animation-timing-function: steps(5);
   animation-iteration-count: infinite;
   background: url('./Sprites/Player/Running/Movement_R1.png');
   z-index: 999;
}

@keyframes spaceFighter {
   to {
      background-position-x: -640px;
   }
}

.spaceFighter {
   margin-left: 10px;
   margin-top: 15px;
   height: 128px;
   width: 128px;
   animation-name: spaceFighter;
   transform: translate(150%, -80%);
   animation-duration: .5s;
   animation-timing-function: steps(5);
   animation-iteration-count: infinite;
   background: url('./Sprites/Player/SpaceFighter/SpaceShip_Blue.png');
   z-index: 999;
}

@keyframes hungryKidRunningR {
   to {
      background-position-x: -256px;
   }
}

.hungry-runningR {
   margin-left: 10px;
   margin-top: 15px;
   height: 64px;
   border-bottom: 2px solid black;
   width: 64px;
   animation-name: hungryKidRunningR;
   transform: translate(-50%, -50%);
   animation-duration: .5s;
   animation-timing-function: steps(4);
   animation-iteration-count: infinite;
   background: url('./Sprites/Player/Running/hungry-kid-running-R.png');
   z-index: 999;
}

@keyframes gamechicken {
   to {
      background-position-x: -3840px;
   }
}

.gamechicken {
   height: 320px;
   width: 320px;
   margin-top: 200px;
   animation-name: gamechicken;
   transform: translate(-50%, -50%);
   animation-duration: 4s;
   animation-timing-function: steps(12);
   animation-iteration-count: infinite;
   background: url('./Sprites/Logos/GameChicken.png');
   z-index: 999;
}

@keyframes cornerchicken {
   from {
      background-position-x: 0px;
   }

   to {
      background-position-x: 0px;
   }
}

@keyframes cornerchicken-hover {
   from {
      background-position-x: 320px;
   }

   to {
      background-position-x: 320px;
   }
}

.cornerchicken {
   position: fixed;
   top: 85%;
   left: 10%;
   height: 320px;
   width: 320px;
   animation-name: cornerchicken;
   transform: translate(-50%, -50%);
   animation-duration: 2s;
   animation-timing-function: steps(2);
   animation-iteration-count: infinite;
   background: url('./Sprites/Logos/LeftCornerChicken.png');
   z-index: 999;
}

.cornerchicken:hover {
   position: fixed;
   top: 85%;
   left: 10%;
   height: 320px;
   width: 320px;
   animation-name: cornerchicken-hover;
   animation-duration: 4s;
   animation-timing-function: steps(2);
   animation-iteration-count: infinite;
   background: url('./Sprites/Logos/LeftCornerChicken.png');
   z-index: 999;
}

.chicken-small {
   height: 96px;
   width: 96px;
   margin-left: 25px;
   margin-top: 10px;
   transform: translate(-50%, -50%);
   animation-duration: 2s;
   animation-timing-function: steps(2);
   animation-iteration-count: infinite;
   background: url('./Sprites/Logos/chicken-small.png');
   z-index: 999;
}

.button-row {
   position: absolute;
   top: 5%;
   left: 49%;
}

.button-row-short {
   position: absolute;
   top: 5%;
   left: 69%;
}

.link-button {
   padding: 10px;
   margin-right: 10px;
   background-color: rgb(33, 161, 33);
   text-decoration: none;
   color: white;
   border-radius: 5px;
   box-shadow: 2px 3px 5px rgb(128, 128, 128);
}

.login {
   background-color: rgb(150, 150, 255) !important;
}

.achievements-button {
   background-color: rgb(65, 182, 81) !important;
}

.achievements-button:hover {
   background-color: rgb(64, 150, 75) !important;
}

.login:hover {
   background-color: rgb(125, 125, 209) !important;
}

.link-button:hover {
   cursor: pointer;
   padding: 10px;
   background-color: rgb(30, 134, 30);
   text-decoration: none;
   color: white;
   border-radius: 5px;
   box-shadow: 0px 0px 5px rgb(128, 128, 128);
}

.p-button-danger {
   background-color: rgb(248, 125, 125) !important;
   border: none !important;
   box-shadow: 2px 3px 5px rgb(128, 128, 128);
}

.p-button-danger:hover {
   background-color: rgb(125, 125, 209) !important;
   border: none !important;
}

.p-button-info {
   background-color: rgb(150, 150, 255) !important;
   border: none !important;
   box-shadow: 2px 3px 5px rgb(128, 128, 128);
}

.p-button-info:hover {
   background-color: rgb(125, 125, 209) !important;
   border: none !important;
}

/* Dialog Styling */
.p-dialog {
   background-color: transparent !important;
   box-shadow: 2px 5px 7px rgb(128, 128, 128) !important;
}

.p-dialog-header {
   background-color: transparent !important;
}

.p-dialog-footer {
   background-color: transparent !important;
}

.p-dialog-content {
   background-color: transparent !important;
}

.p-dialog-titlebar {
   background-color: transparent !important;
}

.p-dialog-title {
   background-color: transparent !important;
}

@media screen and (max-width: 1200px),
screen and (max-height: 900px) {
   .p-dialog {
      background-color: rgba(70, 160, 70, 0.89) !important;
      box-shadow: 2px 5px 7px rgb(128, 128, 128) !important;
   }

   .p-dialog-header {
      background-color: rgba(70, 160, 70, 0.89) !important;
   }

   .p-dialog-footer {
      background-color: rgba(70, 160, 70, 0.89) !important;
   }

   .p-dialog-content {
      background-color: rgba(70, 160, 70, 0.89) !important;
   }

   .p-dialog-titlebar {
      background-color: rgba(70, 160, 70, 0.89) !important;
   }

   .p-dialog-title {
      background-color: rgba(70, 160, 70, 0.89) !important;
   }
}