.highScoreTitle {
   height: 50px;
}

.highScoreRow {
   width: 100% !important;
   height: 1000px !important;
}

.highScoreTable {
   width: 500px !important;
   z-index: 999;
}

.p-datatable-header {
   background-color: rgba(0, 0, 0, 0) !important;
   border: none !important;
   color: green !important;
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
}

.p-datatable-thead {
   border-top: 2px solid rgba(256, 256, 256, 0.3) !important;
}

.p-datatable .p-datatable-thead > tr > th {
   background-color: rgba(228, 228, 250, 0) !important;
   color: green !important;
   border: none !important;
   font-size: 20px;
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
}

.p-datatable .p-datatable-tbody > tr {
   background-color: rgba(228, 228, 250, 0) !important;
   color: green !important;
   border: none !important;
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
}

.p-datatable .p-datatable-tbody > tr > td {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
   border: none !important;

}

.p-datatable {
   box-shadow: 3px 5px 5px rgb(100, 100, 100) !important;
   border: none !important;
   border-radius: 5px;
}

.p-dropdown {
   background-color: rgba(228, 228, 250, 0) !important;
   box-shadow: 2px 3px 5px rgb(150, 150, 150) !important;
}

.p-dropdown-panel {
   background-color: rgb(228, 228, 228) !important;
   background-color: filter(120%);
   box-shadow: 2px 3px 5px rgb(150, 150, 150) !important;
}

.gameDropDownPanel {
   padding-top: 10px;
}

.p-paginator {
   background-color: rgba(228, 228, 228, 0) !important;
   padding: 0px !important;
}

.selectButtonWrapper {
   box-shadow: 2px 3px 5px rgb(150, 150, 150) !important;
   padding: 0 !important;
   margin: 0 !important;
   border-radius: 5px;
}

.p-selectbutton .p-button.p-highlight {
   background: rgba(99, 102, 241, 0.14) !important;
   border: none !important;
   color: green !important;
}

.p-selectbutton .p-button {
   background: rgba(228, 228, 228, 0) !important;
   border: none !important;
   color: green !important;
}

.dailyToggle {
   position: absolute;
   top: 2%;
   left: 2%;
}

.p-togglebutton {
   background-color: transparent !important;
   border: none !important;
   font-size: 12px !important;
   box-shadow: 2px 3px 5px rgb(150, 150, 150) !important;
}

.p-togglebutton:hover {
   box-shadow: 2px 1px 3px rgb(150, 150, 150) !important;
}