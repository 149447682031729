.registerDialog {
   padding: 20px;
   width: 400px;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   border-radius: 5px;
}

.infoDialog {
   padding: 10px;
   width: 350px;
   box-shadow: 2px 3px 5px rgb(100, 100, 100);
   border-radius: 5px;
}

.signUpGrid {
   margin-top: 30px;
}

.signup-button {
   padding: 10px;
   margin-right: 10px;
   background-color: rgb(33, 161, 33);
   text-decoration: none;
   color: white;
   border-radius: 5px;
   width: 100%;
   box-shadow: 2px 3px 5px rgb(128, 128, 128);
}

.signup-button-disabled {
   padding: 10px;
   margin-right: 10px;
   background-color: rgb(30, 134, 30);
   text-decoration: none;
   color: white;
   border-radius: 5px;
   width: 100%;
}

.signup-button-disabled:hover {
   padding: 10px;
   margin-right: 10px;
   background-color: rgb(30, 134, 30);
   text-decoration: none;
   color: white;
   border-radius: 5px;
   width: 100%;
}


.signup-button:hover {
   cursor: pointer;
   padding: 10px;
   background-color: rgb(30, 134, 30);
   text-decoration: none;
   color: white;
   border-radius: 5px;
   box-shadow: 0px 0px 5px rgb(128, 128, 128);
}

.register-form-button {
   width: 300px !important;
}

.homeButton {
   background-color: rgb(248, 125, 125);
}

.homeButton:hover {
   background-color: rgb(187, 97, 97);
}

.checkbox-text {
   margin-left: 15px;
}
